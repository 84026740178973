import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Image, Text } from '@mantine/core';

import { RootState } from 'store';
import { getCatalog } from 'store/catalogs/thunks';
import { sortFarmThenFieldName } from 'util/sortByName';
import { OperationType } from 'store/operation/types';
import { FieldType } from 'store/fields/types';
import { getCropImage } from 'util/overviewResultsDisplay';
import { getCropOrDefault, getLatestCropPlan } from 'util/cropPlans';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { allCategories, CROP_PLAN_HEADERS } from 'constants/cropPlan';
import { getLastSamplingPlanWithResults } from 'util/samplePlan';

import FieldRow from './FieldRow';
import styles from './Container.module.css';

interface PropTypes {
  category: string;
  cropType: string;
  operation: OperationType;
  filteredFields: FieldType[];
  hasFailedList: number[];
  willFetchList: number[];
  isFetchingList: number[];
}

const CropContainer = ({
  category,
  cropType,
  operation,
  filteredFields,
  hasFailedList,
  willFetchList,
  isFetchingList,
}: PropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { catalog } = useSelector((state: RootState) => ({
    catalog: operation?.catalog_id ? state.catalogs.byId[operation.catalog_id] : null,
  }));

  useEffect(() => {
    if (operation.catalog_id && !catalog) {
      dispatch(getCatalog(operation.catalog_id));
    }
  }, [operation, catalog]);

  const sortedFields = sortFarmThenFieldName(operation.fields)
    .filter((field) => {
      const fieldGeom = filteredFields.find(
        (filtField) => field.id === filtField.features[0].properties.id,
      );
      return getCropOrDefault(fieldGeom) === cropType;
    })
    .sort((a, b) => {
      const fieldA = filteredFields.find(
        (filtField) => a.id === filtField.features[0].properties.id,
      );
      const fieldB = filteredFields.find(
        (filtField) => b.id === filtField.features[0].properties.id,
      );
      if (fieldA && !getLastSamplingPlanWithResults(fieldA.features[0].properties, [category])) {
        return 1;
      }
      if (fieldB && !getLastSamplingPlanWithResults(fieldB.features[0].properties, [category])) {
        return -1;
      }
      const cropPlanA = getLatestCropPlan(fieldA);
      const cropPlanB = getLatestCropPlan(fieldB);
      if (cropPlanA && cropPlanB) {
        if (cropPlanA.outstanding_risks?.[category] > cropPlanB.outstanding_risks?.[category]) {
          return 1;
        }
        if (cropPlanB.outstanding_risks?.[category] > cropPlanA.outstanding_risks?.[category]) {
          return -1;
        }
      }
      if (cropPlanA) {
        return 1;
      }
      if (cropPlanB) {
        return -1;
      }
      return 0;
    });

  const headerCount = CROP_PLAN_HEADERS[category].length;
  return (
    <>
      {!!sortedFields.length && (
        <>
          <Grid gutter="xs" className={styles.StickyHeader} columns={4 + allCategories.length}>
            <Grid.Col span={1} className={styles.CropIconWrapper}>
              <Image
                id={`crop-icon-${cropType}`}
                h={40}
                w="auto"
                className={styles.CropIcon}
                src={getCropImage(cropType)}
              />
            </Grid.Col>
            <Grid.Col span={1} className={styles.CropIconWrapper} />
            <Grid.Col span={1} className={styles.HeaderColWrapper}>
              <Text size="xs" fw={700} span>
                {getString('crop', language)}
              </Text>
            </Grid.Col>
            <Grid.Col span={1} className={styles.HeaderColWrapper}>
              <Text size="xs" fw={700} span>
                {getString('targetYield', language)}
              </Text>
            </Grid.Col>
            {CROP_PLAN_HEADERS[category].map((header) => (
              <Grid.Col
                span={allCategories.length / headerCount}
                className={styles.HeaderColWrapper}
                key={header}
              >
                <Text size="xs" fw={700} span>
                  {getString(header, language)}
                </Text>
              </Grid.Col>
            ))}
          </Grid>
          {sortedFields.map((field) => (
            <FieldRow
              key={field.id}
              field={field}
              cropType={cropType}
              fieldGeometry={filteredFields.find(
                (filtField) => field.id === filtField.features[0].properties.id,
              )}
              hasFailedGeometry={hasFailedList.includes(field.id)}
              isFetchingGeometry={isFetchingList.includes(field.id)}
              willFetch={willFetchList.includes(field.id)}
              defaultCatalog={catalog}
            />
          ))}
        </>
      )}
    </>
  );
};

export default CropContainer;
